<script >
// import netFeedback from "@/components/netFeedback.vue";
export default {
  name: "kefu",
  // components: { netFeedback },
  data() {
    return {
      dialogNet: false,
    }
  },
  computed:{
    isPC() {
      let creenWidth=window.innerWidth
      console.log("this.creenWidth-------",creenWidth)
      return creenWidth>1000;
    },
   
  },
  mounted() {

    let creenWidth=window.innerWidth
    console.log("-------------creenwidth",creenWidth)
    // if(creenWidth<1000){
    //
    // }
  },
  methods:{
    uptop(){
      console.log("zhiding")
      this.$emit("uptop",true)
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3589007934247681&webPageTitle=在线咨询")
    },
    showNet(){
      window.open("/netFeedback")
    },
    // showNet() {
    //   this.dialogNet = true
    // },
    hideNet() {
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },
  }
}
</script>

<template>
  <div :class="[isPC ? 'pc-style' : 'mobile-style']">
    <!-- <div class="uptop"  @click="uptop"></div> -->
     <!-- <div class="photo1" style="background-color: #084e8a;border-top-left-radius: 20px;">
      <img src="@/assets/Frame.png" style="width: 80%;height: 80%;padding:  0 0.6vh ;">
      <span style="color: white;font-weight: 400;font-size: 12px;padding:  0 1vh 1vh 1vh;">Consult</span>
     </div> -->
     <div style="padding: 0.5vh 0 0 ;background-color: #084e8a;border-top-left-radius: 20px;"  @click="upkefu">
      <div class="upkefu" ></div>
      <div class="title1" style="font-weight: 400;font-size: 12px"> Consult</div>
    </div>
     <!-- <div style="height: 1px;background-color: rgba(128,128,128,0.3);width: 100%"></div> -->
     <!-- <div class="photo2" style="background-color: #084e8a;border-bottom-left-radius: 20px;">
    <img src="@/assets/Frame (1).png" style="width: 80%;height: 80%;padding:  0 0.6vh;">
    <span style="color: white;font-weight: 400;font-size: 12px;padding:  0 1vh 1vh 1vh;">Feedback</span>
    </div> -->
    <!-- <div style="padding: 1vh 0;" @click="upkefu">
      <div class="upkefu" ></div>
      <div class="title1" style=""> Service</div>
    </div> -->
        <div style="padding:  0 0 1vh;background-color: #084e8a;border-bottom-left-radius: 20px;" @click="showNet">
      <div class="upkefu2"></div>
      <div class="title1" style="font-weight: 400;font-size: 12px"  > Feedback</div>
    </div>
    <!-- <netFeedback :dialogVisible="dialogNet"></netFeedback> -->
  </div>
</template>

<style scoped lang="scss">
.pc-style {
  z-index: 99;
  cursor: pointer;
  position: fixed;
  top: 40%;
  right: 0;
  padding: 5px;
  width: 3vw;
  height: fit-content;;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  // background-color: rgb(50, 119, 220);

  //border-radius: 50%;
  //box-shadow: 5px 0 14px 0px #D4E4FF;

  .uptop {
    width: 2vw;
    height: 2vw;

    margin: 1vh auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .upkefu {
    margin: 0.2vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/kefu2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu2 {
    margin: 0.2vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/Frame (1).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{
    font-size: 0.8vw;text-align: center;margin-top: 0.5vh;color: white;word-break: break-all;
  }
  .feedback {
    padding: 0.5vh 0;
    margin: 1vh auto;
    font-size: 0.8vw;
    text-align: center;
    color: white;
  }

}

.mobile-style{
  cursor: pointer;
  position: fixed;
  top:43%;
  z-index: 98  ;
  right:0;
  // background-color: rgb(50, 119, 220);
  // padding:5px;
  width: 60px;
  height:fit-content;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  //border-radius: 50%;
  //border: 5px solid #1295d9;
  .uptop{
    width:15px;
    height:15px;
    margin:5px auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu{
    margin:0.2vh auto;
    width:9vw;
    height:9vw;
    background-image: url("../assets/Frame.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu2{
    margin:0.2vh auto;
    width:9vw;
    height:9vw;
    background-image: url("../assets/Frame (1).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{
    font-size: 10px;text-align: center;margin-top: 0.5vh;color: white;word-break: break-all;
  }
  .wechaticon {
    width: 200px;
    height: 50px;
    background-color: #f0f0f0;
    transition: background-color 0.3s; /* 平滑过渡效果 */
  }
  .wechaticon.show-tooltip .tooltip {
    /* 当showTooltip为true时显示提示框 */
    opacity: 1;
  }

  .wechaticon:hover {
    background-color: #3498db; /* 鼠标悬停时的背景颜色 */
  }

  .feedback{
    cursor: pointer;
    width: fit-content;
    height:fit-content;
    color: white;
    font-size: 8px;
    margin:0 auto;
    text-align: center;
    //padding: 10px 0;
    display: flex;
    justify-content: center;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border: 5px solid #1295d9;
  }
}
</style>
