<!--
 * @Author: twy+1-at-831623936441 ISfIgyagCK159zOXbALBCYfPJsXX7JI+UcjxsoGvWhTuiwzjxNFOeYUELbg=
 * @Date: 2025-01-16 16:35:40
 * @LastEditors: twy+1-at-831623936441 ISfIgyagCK159zOXbALBCYfPJsXX7JI+UcjxsoGvWhTuiwzjxNFOeYUELbg=
 * @LastEditTime: 2025-01-17 20:40:02
 * @FilePath: \groundEn\src\views\component\ServiceFeedback.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="FeedbackContainer">
    <div style="padding: 0 10px">
      <div class="title">
        Your Service and Feedback
      </div>
      <div class="title2">
        Let us serve you better
      </div>
    </div>
  
    <div class="imagedesign" >
      <img src="../../assets/image 39.png">
    </div>
    <div style="padding: 0 10px 2vh">
      <div class="title3"   @click="showNet">
        Your feedback will help us optimize solutions, improve efficiency, and create greater value for you.<br>
        <img src="../../assets/Arrow 2.png" style="width: 5vw">
        &nbsp;&nbsp;<span style="color: #084E8A">Leave a suggestion</span>
      </div>
    </div>
    <div class="imagedesign" >
      <img src="../../assets/image (4).png">
    </div>
    <div style="padding: 0 10px 2vh">
      <div class="title3" @click="upkefu">
        <span style="color: #084E8A">Online consultation<br></span>
        <div style="font-size: 5vw;margin: 1vh 0">
          Together, let's find the best solution.<br>
        </div>
        Let's work together to create a tailored strategy and wish you success in achieving your goals.<br>
        <img src="../../assets/Arrow 2.png" style="width: 5vw">
        &nbsp;&nbsp;<span style="color: #084E8A">Start a consultation</span>
      </div>
    </div>
  </div>
  </template>
  <script>
  export default {
    methods:{
      showNet(){
        console.log("----chuda---------------")
        window.open("/netFeedback")
      },
      upkefu(){
        window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
      },
    }
  
  }
  </script>
  
  <style scoped lang="scss">
  .FeedbackContainer{
    .title{
      font-size: 6vw;
      color: #084E8A;
      padding: 4vh 0 2vh;
    }
    .title2{
      font-size: 5vw;
      // letter-spacing: 0.5vw;
    }
    .imagedesign{
      width: 100vw;
      height: 50vw;
      margin: 3vh 0;
      position: relative;
      img{
        position: absolute;
        top: 0;
      }
    }
    .title3{
      font-size: 3.3vw;
      line-height: 6vw;
    }
  }
  </style>