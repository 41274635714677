<template>
    <div class="about-section">
        <!-- 标题和简介 -->
        <div class="title">About us</div>
        <div class="intro_title">Institute Introduction</div>
        <div class="intro">{{ introduction }}</div>

        <!-- 数据统计区 -->
        <div class="stats-container">
            <!-- 大卡片 -->
            <div v-for="stat in mainStats" :key="stat.id" class="stat-card large">
                <img :src="stat.icon" :alt="stat.desc" class="stat-icon">
                <div class="stat-content">
                    <div class="number">{{ stat.number }}</div>
                    <div class="desc">{{ stat.desc }}</div>
                </div>
            </div>

            <!-- 小卡片区域 -->
            <div class="small-stats">
                <div v-for="stat in subStats" :key="stat.id" class="stat-card small">
                    <div class="number">{{ stat.number }}</div>
                    <div class="desc">{{ stat.desc }}</div>
                </div>
            </div>
        </div>

        <!-- 视频展示区 -->
        <div class="video-container">
            <video :src="videoInfo.src" controls playsinline>
                {{ videoInfo.title }}
            </video>
        </div>

        <!-- 人才卡片区 -->
        <div class="talent-container">
            <div class="talent-card" :style="{ backgroundImage: `url(${talentBgImage})` }">
                <div v-for="card in talentCards" :key="card.id" class="talent-section">
                    <img :src="card.icon" :alt="card.title" class="talent-icon">
                    <div class="talent-title">{{ card.title }}</div>
                    <div class="talent-desc">
                        <p v-for="(line, index) in card.desc" :key="index">{{ line }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// 导入统计图标
import dataIcon from '../../../assets/home/about/data-icon.png'
import locationIcon from '../../../assets/home/about/location-icon.png'

// 导入视频相关资源

import instituteVideo from '../../../video/introduce.mp4'

// 导入人才卡片相关资源
import talentTeamIcon from '../../../assets/home/about/talent-team-icon.png'
import enterpriseIcon from '../../../assets/home/about/enterprise-icon.png'
import talentBgImage from '../../../assets/home/about/talent-bg.png'

export default {
    name: 'AboutSection',

    data() {
        return {
            // 研究院简介
            introduction: "With the core of reservoir geology and engineering technology, the combination of geology and engineering, and the characteristics of production enhancement, together with digital and visual means, we provide oilfield customers with services of research and optimisation solutions for production enhancement and efficiency, and improve the efficiency of engineering development.",

            // 大数据统计卡片
            mainStats: [
                {
                    id: 1,
                    icon: dataIcon,
                    number: 100,
                    desc: "We have 100 oil reservoir databases"
                },
                {
                    id: 2,
                    icon: locationIcon,
                    number: 55,
                    desc: "We serve 55+ countries"
                }
            ],

            // 小数据统计卡片
            subStats: [
                {
                    id: 1,
                    number: 5000,
                    desc: "5000+ Senior Experts"
                },
                {
                    id: 2,
                    number: 300,
                    desc: "300+ Specialized Technologies"
                }
            ],

            // 视频信息
            videoInfo: {
                src: instituteVideo,
                title: "Oil and Gas Field Development Research Institute"
            },

            // 人才卡片背景图
            talentBgImage: talentBgImage,

            // 人才卡片数据
            talentCards: [
                {
                    id: 1,
                    icon: talentTeamIcon,
                    title: "Creating a platform for professionals",//专业人才共创平台
                    desc: [
                        "Reservoir research professionals",
                        "Local experts",
                        "Expertise in Geological Processes"
                    ]
                },
                {
                    id: 2,
                    icon: enterpriseIcon,
                    title: "Enterprise Co-creation Platform",//企业共创平台
                    desc: [
                        "Co-creation of oil reservoir research enterprises",
                        "Co-creation of Reservoir Service Companies",
                        "Integrated Technical Services"
                    ]
                }
            ]
        }
    },

    methods: {
        // 视频播放控制
        handleVideoPlay() {
            console.log('视频开始播放')
        },

        handleVideoPause() {
            console.log('视频暂停')
        },

        handleVideoEnded() {
            console.log('视频播放结束')
        },

        handleCardClick(type) {
            console.log('卡片点击:', type)
        }
    },

    mounted() {
        const video = this.$el.querySelector('video')
        if (video) {
            video.addEventListener('play', this.handleVideoPlay)
            video.addEventListener('pause', this.handleVideoPause)
            video.addEventListener('ended', this.handleVideoEnded)
        }
    },

    beforeDestroy() {
        const video = this.$el.querySelector('video')
        if (video) {
            video.removeEventListener('play', this.handleVideoPlay)
            video.removeEventListener('pause', this.handleVideoPause)
            video.removeEventListener('ended', this.handleVideoEnded)
        }
    }
}
</script>
<style lang="scss" scoped>
.about-section {
    padding: 25px 10px;
    // background: #002B48;
    color: #FFFFFF;
    font-family: Sarial, sans-serif, "Microsoft Yahei" !important;
    .title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .intro_title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .intro {
        font-size: 14px;
        line-height: 1.8;
        margin-bottom: 20px;
        // opacity: 0.9;
    }

    .stats-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 20px;

        .stat-card {
            border-radius: 15px;
            border: 1px solid #FFF;
            background: #FFF;
            padding: 15px;

            // 第一个卡片的样式
            &:nth-child(1) {
                color: #123456;

                border-radius: 15px;
                border: 1px solid #FFF;
                background: #FFF;
            }

            // 第二个卡片的样式
            &:nth-child(2) {
                color: #FFF;
                border-radius: 15px;
                border: 1px solid #FFF;
                background: #123456;
            }

            &.large {
                display: flex;
                align-items: center;
                gap: 20px;

                .stat-icon {
                    margin-left: 5px;
                    width: 80px;
                    height: 80px;
                }

                .stat-content {
                    flex: 1;
                    text-align: center;

                    .number {
                        font-size: 70px;
                        // font-weight: bold;
                        margin-bottom: 4px;
                    }

                    .desc {
                        font-size: 18px;
                        // opacity: 0.9;
                    }
                }
            }
        }

        .small-stats {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;

            .stat-card.small {
                text-align: center;
                padding: 20px 5px 27px 5px;

                &:nth-child(2) {
                    background: #FFF;

                    .number,
                    .desc {
                        color: #123456;
                    }
                }

                .number {
                    color: #123456;
                    font-size: 70px;
                    font-weight: bold;
                    margin-bottom: 4px;
                }

                .desc {
                    color: #123456;
                    font-size: 17px;
                    line-height: 20px;
                }
            }
        }
    }

    .video-container {
        margin-bottom: 20px;
        border-radius: 8px;
        overflow: hidden;

        video {
            width: 100%;
            display: block;
            background: #000;
        }
    }

    .talent-card {
        display: flex;
        justify-content: space-between;
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        padding: 9px 9px 20px 9px;
        position: relative;
        height: 240px;  
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 15px;
            background: linear-gradient(180deg, rgba(39, 114, 188, 0.00) 0%, rgba(18, 52, 86, 0.75) 100%);
            // border-radius: 8px;
        }

        .talent-section {
            position: relative;
            z-index: 1;
            width: calc(50% - 15px); 
            height: 100%;    
            padding: 20px 15px 0 0px;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;  
            &:first-child::after {
                content: '';
                position: absolute;
                right: 0;
                top: 40%;  // 不从最顶部开始
                height: 60%;  // 不到最底部
                width: 1px;
                background: #FFF;
            }


            .talent-icon {
                width: 24px;
                height: 24px;
                margin-bottom: 10px;
            }

            .talent-title {
                font-size: 12px;
                font-weight: 500;
                color: #FFF;
                margin-bottom: 10px;
                
            }

            .talent-desc {
                color: #FFF;
                font-size: 10px;
                line-height: 1.2;

                p {
                    margin: 0;
                    // margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>