<template>
  <div>
    <div v-if="model===false">
      <div class="screen">
        <div style="height:75%;display: flex;justify-content: space-between;padding:3.023609394314vh  3vw;">
          <div class="left">
            <div class="left3" style="width: fit-content">
              <span style="cursor: pointer;color: rgba(255,255,255,1);" @click="gptsProfessional()">Geological Engineering Cloud Platform</span><br>
              <!-- <div style="color:rgba(225,240,255,1)" @click="gptsMove1()" class="community">Anton Cooperation Business Service Platform</div>
              <div style="color:rgba(225,240,255,1)" @click="gptsMove2()" class="community">Oil & Gas Dao  </div>
              <div style="color:rgba(225,240,255,1)" @click="gptsMove3()" class="community">Oil & Gas Community   </div>
              <div style="color:rgba(225,240,255,1)" @click="gptsMove4()" class="community">Oil & Gas Generic AI    </div>
              <div style="color:rgba(225,240,255,1)" @click="gptsMove5()" class="community"> Oil & Gas Information  </div>
              <div style="color:rgba(225,240,255,1)" @click="gptsMove6()" class="community"> Oil & Gas JobAI  </div> -->
              <div style="color:rgba(225,240,255,1)" @click="andongzaixian()" class="community"> Anton Online Services</div>
              <div style="color:rgba(225,240,255,1)" @click="gptsMove4()" class="community">Oil and Gas Connect</div>
              <div style="color:rgba(225,240,255,1)" @click="gptsMove1()" class="community">Antong Oil and Gas Mall</div>
              <div style="color:rgba(225,240,255,1)" @click="youtian" class="community">Oilfield Management</div>
              <div style="color:rgba(225,240,255,1)" @click="tongao" class="community">Tongao Testing</div>
              <div style="color:rgba(225,240,255,1)" @click="anyi" class="community">Anyineng Rental</div>
            </div>
            <div class="left3">
              <span style="cursor: pointer" @click="gptsProfessional()">Geological Engineering Cloud Platform</span><br>

              <div style="color:rgba(225,240,255,1)" @click="gptsGround3()" class="community"> Global Online Expert Support Services  </div>
              <div style="color:rgba(225,240,255,1)" @click="gptsGround1()" class="community"> Geological Engineering GPTs</div>
              <div style="color:rgba(225,240,255,1)" @click="gptsGround2()" class="community"> Oil & Gas Field Development Series Technical Services    </div>
            </div>
            <div class="left1">
              <span style="cursor: pointer">Service Feedback</span><br>
              <div style="color:rgba(225,240,255,1)" class="community" @click="showComment">Operation Project Evaluation Feedback</div>
              <div style="color:rgba(225,240,255,1)" class="community" @click="showProduct">Product Evaluation Feedback</div>
              <div style="color:rgba(225,240,255,1)" class="community" @click="showNet">User Feedback for Website  </div>
            </div>
<!--            <div class="left2">-->
<!--              <span @click="dialogVisible = true" style="cursor:pointer;color:#686eff;border-bottom: #686eff 1px solid">Cooperation Consulting</span><br>-->
<!--              &lt;!&ndash;              <div class="community">技术咨询：<span @click="dialogVisible = true">产品咨询</span></div>&ndash;&gt;-->
<!--              <div class="community">Contact us：<span>+86-13880250028</span></div>-->
<!--            </div>-->
          </div>
          <div class="right">

            <div class="left2" @click="mobile1">
              <div class="up">
                <img loading="lazy" src="@/assets/ground.png">
              </div>
              <div class="down">Mobile terminal</div>
            </div>
            <div class="left2" @click="mobile2">
              <div class="up">
                <img loading="lazy" src="@/assets/groundAntonOffice.png">
              </div>
              <div class="down">Anton Oilfield Services Group</div>
            </div>
            <div class="left2" @click="mobile3">
              <div class="up">
                <img loading="lazy" src="@/assets/groundAnton.png">
              </div>
              <div class="down">Anton Online</div>
            </div>
          </div>
        </div>
        <div class="foot">
          <div class="flex">
            <p>No.5 Qingxiuyuan Street, Jingjia Road, Yanqi Town, Huairou District, Beijing</p>
            <span> | </span>
            <p>Copyright AnTon Institute of Geological Engineering Technology (Beijing) Co., Ltd</p>
            <span> | </span>
            <p class="red">
              <a href="https://beian.miit.gov.cn/">京ICP备2024077635号-1</a>
            </p>
          </div>
          <div
              style="
          width: fit-content;
          font-size: 12px;
          margin: 0 auto;
          padding: 5px 0px 10px;
        "
          >
            <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502056088"
                style="display: inline-block; text-decoration: none"
            >
              <img loading="lazy" src="@/assets/beian.png" style="float: left; width: 17px" />
              <p
                  style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
              >
                京公网安备 11010502056088号
              </p></a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="screenModel" ref="screenModel">
        <div class="foot">
          <div class="left1">
            <span @click="gptsProfessional()" style="color: white;font-size: 14px">Geological Engineering Cloud Platform</span>
            <span @click="hidden3" v-if="hid2===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>
            <span @click="hidden23" v-if="hid2===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
            <br>

            <div @click="gptsGround3()" class="community"> Global Online Expert Support Services  </div>
            <div @click="gptsGround1()" class="community"> Geological Engineering GPTs</div>
            <div @click="gptsGround2()" class="community"> Oil & Gas Field Development Series Technical Services    </div>
          </div>

          <div class="left1" >
            <span @click="gpt3()" style="color: white;font-size: 14px">Service Feedback</span>
            <span @click="hidden" v-if="hid===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>
            <span @click="hidden2" v-if="hid===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
            <br>
            <div v-if="hid===0" @click="showCommentPhone()" class="community">Operation Project Evaluation Feedback</div>
            <div v-if="hid===0" @click="showProductPhone()" class="community">Product Evaluation Feedback</div>
            <div v-if="hid===0" @click="showNetPhone()" class="community">User Feedback for Website</div>
          </div>
<!--          <div class="left2">-->
<!--            <span style="color: white;font-size: 14px">Cooperation Consulting</span>-->
<!--            <span @click="hidden1" v-if="hid1===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>-->
<!--            <span @click="hidden12" v-if="hid1===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>-->
<!--            <br>-->
<!--           <div v-if="hid1===0" class="community">Contact Us:<span>+86-13880250028</span></div>-->
<!--          </div>-->
          <div class="left3">
          </div>
          <div class="flex">
            <p>No.5 Qingxiuyuan Street, Jingjia Road, Yanqi Town, Huairou District, Beijing</p>
          </div>
          <div class="flex">
            <p>Copyright AnTon Institute of Geological Engineering Technology (Beijing) Co., Ltd</p>
          </div>
          <div
              style="
          width: fit-content;
          font-size: 12px;
          margin: 0 auto;
          padding: 5px 0px 10px;
        "
          >
            <a
                target="_blank"
                style="display: inline-block; text-decoration: none"
            >
              <p
                  style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
              >
                京ICP备2024077635号-1          <br>  京公网安备 11010502056088号
              </p></a
            >
          </div>
        </div>
      </div>
    </div>
    <consultTip
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
    ></consultTip>
  </div>

</template>


<script>


export default {

  name: "",
  props: [],

  data() {
    return {
      hid:0,
      hid1:0,
      hid2:0,
      dialogVisible: false,
      model:false,
    }

  },
  beforeRouteLeave(to, from, next) {
    // 保存滚动位置
    this.scrollPosition = this.$refs.screenModel.scrollTop;
    next();
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 恢复滚动位置
      vm.$nextTick(() => {

        vm.$refs.screenModel.scrollTop = vm.scrollPosition;

      });
    });
  },
  methods: {
    andongzaixian(){
      window.open("https://cnmall.antonoil.com/")
    },
    antong(){
      window.open("https://cn.atoilgas.com/ ")
    },
    youtian(){
      window.open("https://www.aomserv.com/en ")
    },
    tongao(){
      window.open("https://www.t-all.cn/")
    },
    anyi(){
      window.open("https://cn.antoneasy.com/")
    },
    showCommentPhone(){
      window.open("/Feedback", "_blank");
    },
    showProductPhone(){
      window.open("/ProductFeedback", "_blank");
    },
    showNetPhone(){
      window.open("/netFeedback", "_blank");
    },
    showComment(){
      this.$emit("showComment")
    },
    showProduct(){
      this.$emit("showProduct")
    },
    showNet(){
      this.$emit("showNet")
    },

    hidden(){
      this.hid=1
    },
    hidden2(){
      this.hid=0
    },
    hidden1(){
      this.hid1=1
    },
    hidden12(){
      this.hid1=0
    },
    hidden3(){
      this.hid2=1
    },
    hidden23(){
      this.hid2=0
    },
    gpts1(){
      window.open("https://www.oilgascommunity.com/sortList?id=40", "_blank");
    },
    gpts2(){
      window.open("https://www.oilgascommunity.com/sortList?id=41", "_blank");
    },
    gpt3(){
      window.open("/about", "_blank");
    },
    gptsProfessional(){

      console.log(localStorage.getItem("home"))
      if (localStorage.getItem("home")==="1") {
        this.$emit("showDialogBottom",0);
      } else {
        sessionStorage.setItem("ground", 0)
        window.open("/", "_blank");
      }
    },
    gptsMove1(){
      window.open("https://www.atoilgas.com/")
    },
    gptsMove2(){
      window.open("https://cn.oilgasdao.com/")
    },
    gptsMove3(){
      window.open("https://www.oilgascommunity.com/")
    },
    gptsMove4(){
      window.open("https://www.oilgasgpts.com/")
    },
    gptsMove5(){
      window.open("https://www.oilgasinfoai.com/")
    },
    gptsMove6(){
      window.open("https://www.oilgasjobai.com/")
    },
    mobile1(){
      window.open("https://www.ogdri.com/")
    },
    mobile2(){
      window.open("https://www.antonoil.com/")
    },
    mobile3(){
      window.open("https://mall.antonoil.com/")
    },
    gptsGround1(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",1);
      } else {
        sessionStorage.setItem("ground", 1)
        window.open("/", "_blank");
      }

    },
    gptsGround2(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",2);
      } else {
        sessionStorage.setItem("ground", 2)
        window.open("/", "_blank");
      }

    },
    gptsGround3(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",3);
      } else {
        sessionStorage.setItem("ground", 3)
        window.open("/", "_blank");
      }
    },
    gptsGround4(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",4);
      } else {
        sessionStorage.setItem("ground", 4)
        window.open("/", "_blank");
      }

    },
    gptsGround5(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",6);
      } else {
        sessionStorage.setItem("ground", 6)
        window.open("/", "_blank");
      }

    },

    gptsProfessional1(){
      sessionStorage.setItem("skip", 5)
      if (localStorage.getItem("home")==="1") {
        window.location.reload()
      } else {
        this.$router.push("/");
      }



    },
    gptsDesign1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptOil");
    },
    gptsTranslate1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptTranslate");
    },
    gptsTechnology1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptHole",);
    },
    gpts11(){
      sessionStorage.setItem("skip", 0)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=40';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    gpts21(){
      sessionStorage.setItem("skip", 3)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=41';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    gpts13(){
      sessionStorage.setItem("skip", 3)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=39';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    hideDialog() {
      this.dialogVisible = false;
    },

  },

  mounted() {

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }


  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.screen{
  z-index: 9999999999999999999;
  width: 100%;
  height: fit-content;
  // background-color: #333333;
  background-color: rgba(8,78,138,1);
  .foot {
    padding-top: 15px;
    text-align: left;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }
  .yuming{
    color:white;

  }

  .left{
    padding-top: 3vh;
    display: flex;
    color:#FFFFFF;
    .left1{
      margin-left: 2vw;
      width: fit-content;
      font-size: 1.2vw;
     font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 5vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size:  1vw
      }
      .community:hover{
        color:white;
        font-size:  1vw
      }
    }
    .left3{
      margin-left: 0.6932409012vw;
      width: fit-content;
      font-size: 1.2vw;
     font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 5vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size:  1vw
      }
      .community:hover{
        color:white;
        font-size:  1vw
      }
    }
    .left2{

      margin-left: 2vw;
      width: fit-content;
      font-size:1.2vw;
     font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 5vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size:  1vw
      }
      .community:hover{
        color:white;
        font-size:  1vw
      }
    }

  }
  .right{
    margin-top: 2vh;
    display: flex;
    color:#FFFFFF;
    .left2{
      cursor: pointer;
      margin-left:1vw;
      width: 6vw;
      font-size:  1vw;
     font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 3.33745364647713vh;
      .up{
        margin:0 auto;
        width: 6vw;
        height: auto;
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat
      }
      .down{
        width:  6vw;
        margin:0 auto;
        text-align: center;
      }
    }

  }
}
.screenModel{
  width: 100%;
  height: fit-content;
  background-color: #333333;
  .left1{
    width: 90%;
    margin:0 15px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    .community{
      margin-left: 12px;
      color:gray;
      cursor: pointer;
      font-size:12px
    }

  }
  .left2{
    width: 90%;
    margin:0 15px;
    color: white;
    text-align: left;
    font-size: 14px;
   font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 400;
    line-height: 32px;
    .community{
      margin-left: 12px;
      color:gray;
      cursor: pointer;
      font-size: 12px
    }
  }
  .left3{
    width: 100%;
    margin:15px 0;
    height: 1px;
    background-color: #484848;
  }

  .foot {
    padding-top: 15px;
    text-align: center;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }

}
</style>
