<template>
                   <!--       油藏地质精细研究服务-->
                   <div ref="professional" class="screenConsultnew1" style="background-color: white">
          <div class="icon-photo" >
            <img src="@/assets/new/icon.png" style="width:7%;height:7%;margin-left:43vw;margin-bottom: 3vh;" class="icon">
          </div>
<div style="display: flex;margin:0 auto;">
  <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
    <div class="title1" v-sliden-in="{duration:800}" v-zoom-in="{duration:800}">&nbsp;Precision Engineering Technical Services</div>
  </div>
</div>
<div class="serviceStyle">
  <div class="text-content">
                <div class="main-description">Geological Research Services for Reservoirs
          </div>
          <div class="sub-description">
            With a global perspective, focusing on detailed research for individual wells, and characterized by the integration of geological and engineering studies,
             we provide clients with comprehensive geological research evaluation and development plan formulation services.
          </div>
          <!-- Discover More 按钮 -->
          <div class="discover-more">
            <button class="discover-btn" @click="about">Know More ></button>
          </div>
        </div>
        <!-- 右侧轮播图 -->
        <div class="pro2">
<div class="swiper-containerpc">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new1.png">
            <div class="photohover"></div>

          </div>
        </div>
        <div class="back1">
          <div class="title6" @click="comment" > Services for Oil and Gas Reservoirs</div>

        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/groundHome2.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title3" @click="exploit"> Development Plan Design Services</div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/groundHome3.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title4"  @click="naturalfrac"> Natural  Prediction Technology </div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/groundHome4.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title5" @click="geomechanics">Geomechanics Research  Services</div>
        </div>
      </div>
    </div>
    <div class="swiper-slide" >
      <div class="grand1">
        <div class="back">
          <div class="photo">
            <img src="@/assets/new/groundHome5.png">
            <div class="photohover"></div>
          </div>
        </div>
        <div class="back1">
          <div class="title5" @click="thinreservoir">3D Geomechanics Model Services</div>
        </div>
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</div>
</div>
</div>

</div>
</template>

<script>
export default {
  name: 'youzang',
  methods: {
    about(){
      this.$emit("about",true)
    },
    comment(){
      this.$emit("comment",true)
    },
    exploit(){
      this.$emit("exploit",true)
    },
    naturalfrac(){
      this.$emit("naturalfrac",true)
    },
    geomechanics(){
      this.$emit("geomechanics",true)
    },
    thinreservoir(){
      this.$emit("thinreservoir",true)
    }
  }
}
</script>

<style lang="scss" scoped>
.screenConsultnew1 {
    //z-index: 10;
    width: 100vw;
    height: fit-content;
    // border-radius: 9px;
    padding: 5vh 4.09763142692085vw 2vh 4.09763142692085vw;
    background: transparent;
    // border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */

    .title1 {

      //height: 5vh;
      opacity: 1;
      // font-size: 3vw;
      font-size: 60px;
       font-weight: 400;
      // color: #15257b;
      color: rgba(8,78,138,1);
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      line-height: 10vh;
    }

    .light1 {
      margin: 3vh auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .title {

      margin-top: 6vh;
      height: 5vh;
      opacity: 1;
      font-size: 1.5vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh;
      text-align: left;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 80vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .serviceStyle {
      margin-top: 5vh;
      display: flex;
      //grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      column-gap: 0.5vw;
      // overflow: hidden;
      width:100%;
      border: 1px solid #E1E1E1; /* 1px 宽度，实线，浅灰色边框 */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* 更重的阴影效果 */
      border-radius: 9px;
      .text-content {
            margin-left:1.5vw;
            width:29%;
            margin-top:5vh;
            .main-description {
                font-size: 1.4vw;
                line-height: 1.8;
                margin-bottom: 2vh;
                // color: #000000;
                color:#555555;
            }

            .sub-description {
                font-size: 1.1vw;
                line-height: 1.8;
                // color: #000000;
                color:#616161;
                margin-bottom: 2vh;
            }

            .discover-more {
             

                .discover-btn {
                    background: none;
                    border: none;
                    color: #15257b;
                    // color: #084E8A;
                    font-size: 1.1vw;
                    font-weight: 500;
                    padding: 0;
                    cursor: pointer;
                    transition: color 0.3s;
                    // margin-bottom: 1vh;

                    &:hover {
                        color: darken(#15257b, 10%);
                    }
                }
            }
          }
           .pro2 {
      width:70%;
      height: fit-content;
      // padding-top: 2vh;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 9px;
        padding: 1vw;
        opacity: 1;
        position: relative;
        background-color: white;
        height: fit-content;
        .back {
          width: 100%;
          margin:0 auto 0 0;
          height:fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height:fit-content;
          margin-top:2vh;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 0;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          display: flex;
          height: 29vh;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          position: relative;
        }
        .photohover{
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }
        //.photolist {
        //  font-size: 5vw;
        //  color: #e3e8ed;
        //  margin:auto 1vw 0 2vw;
        //  height: 100%;
        //  width: fit-content;
        //  font-weight: bold;
        //}

        .title1 {
          text-align: left;
          font-size: 1.5vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          margin-right:5vw;
          margin-top:-3vh;
        }
        .title6 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }
        .title3 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          // margin-left:1vw;
        }
        .title4 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          margin-left:0.6vw;
        }
        .title5 {
          text-align: left;
          font-size: 1.2vw;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
          // margin-left:1.9vw;
          // margin-right: 2vw;
        }

        .title2 {
          display: flex;
          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size:1vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 1vh 1vw;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }
      .grand1:hover{
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }
    }
}
  }
</style>